<template>
  <div id="app">
    <!-- <Xitem msg="Welcome to Your Vue.js App"/> -->
    <router-view/>
  </div>
</template>

<script>
// import Xitem from './components/project/index.vue'
// import('flex-native');
export default {
  name: 'App',
  components: {
  //  Xitem
  },
   created () {
    var userAgent = navigator.userAgent   // 获取浏览器信息
    var IEReg = new RegExp('MSIE \\d+\\.\\d+;');   // 正则校验IE标识符
    var IEMsg = IEReg.exec(userAgent)   // 获取字符串
    var IEVersionNum = new RegExp('\\d+\\.\\d');   // 正则获取版本
    
    // 是IE9，引入js文件
    if(IEMsg && parseFloat(IEVersionNum.exec(IEMsg)) == 9){
      import('@/config/index.css')
        // import('@/config/jquery.xdomainrequest.min.js')
      console.log('IE9引入')
    }else{
      //  import('@/config/download.js')
      console.log('非IE9不引入')
    }
  },
  destroyed() {
//  window.removeEventListener('resize', this.resizeWin)
}
}
</script>

<style>
.el-loading-mask {
  z-index: 9999999999 !important;
}
/* @import url('./utils/css/common.scss'); */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
html , body{
  background: #EEEEEE;
  height: 100% !important;
  /* webkit-user-drag: none; */
/*    
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
}
img{
   user-select: none;
   -webkit-user-drag: none;
   pointer-events:none;

}
.app {
  
background: #EEEEEE;
  /* padding: 0 20%; */
}
/* .is-process{
  font-size: 16px !important;
}
.el-step__title{
   font-size: 16px !important;
} */
*{
    margin:0;
    padding:0
}
li{
    list-style:none
}
img{
    vertical-align:top;
    border:none
}
</style>
