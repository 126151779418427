import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

const routes = [
  {
    //没写就是任意路由都是定向这个
    path: "",
    //重定向
    redirect: "/name",
  },
  {
    path: "/name",//1
    name: "name",
    component: () => import("../view/home"),
    // children: [
    //   {
    //     path: '/public', // 默认子路由，只能有1个
    //     name: 'public',
    //     component: () => import('@/components/public')
    //   },
    // ]
  },
  {
    path: "/project",
    name: "project",//支付
    component: () => import("../components/project"),
    // children: [
    //   {
    //     path: '/public', // 默认子路由，只能有1个
    //     name: 'public',
    //     component: () => import('@/components/public')
    //   },
    // ]
  },
  {
    path: "/added",//3
    name: "added",
    component: () => import("@/components/added"),
  },
  {
    path: "/myinvoice",
    name: "myinvoice",
    component: () => import("@/components/myinvoice"),
  },
  {
    path: "/mycompany",
    name: "mycompany",
    component: () => import("@/components/mycompany"),
  },
  {
    path: "/project/public",
    name: "public",
    component: () => import("../components/public"),
  },
  {
    path: "/project/defray",//已出函页面
    name: "defray",
    component: () => import("../view/defray"),
  },
  {
    path: "/shouxin",//5
    name: "shouxin",
    component: () => import("../view/qianzhang/shouxin.vue"),
  },
  {
    path: "/xieyi",//6
    name: "xieyi",
    component: () => import("../view/qianzhang/xieyi.vue"),
  },
  {
    path: "/gaizhang",//7
    name: "gaizhang",
    component: () => import("../view/qianzhang/gaizhang.vue"),
  },




  {
    path: "/project/successful",
    name: "successful",
    component: () => import("../view/successful"),
  },
  {
    path: "/project/success",
    name: "success",
    component: () => import("../view/success"),
  },
  {
    path: "/project/invoice",//退保页面
    name: "invoice",
    component: () => import("../view/invoice")
  }
];
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode:'hash',
  // mode: "history",
  routes,
});
export default router;

