import 'babel-polyfill';
import Es6Promise from 'es6-promise' // 引入es6-promise模块
Es6Promise.polyfill() // 挂载模块
import 'promise-polyfill/src/polyfill';
import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
// import clipboard from 'clipboard';
import axios from "axios"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

Vue.prototype.$axios = axios;
Vue.prototype.$url = 'http://www.hnzyxsbh.com'
Vue.prototype.$baseurl = "http://www.hnzyxsbh.com/tripartite/"
//@方便不同的地方使用src目录
import router from './router/router';
Vue.config.productionTip = false
//导入vant组件库 
Vue.filter("dateFormat", function (value) {
  let date = new Date(value * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let y = date.getFullYear();
  let MM = date.getMonth() + 1;
  MM = MM < 10 ? "0" + MM : MM;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let m = date.getMinutes();
  m = m < 10 ? "0" + m : m;
  let s = date.getSeconds();
  s = s < 10 ? "0" + s : s;
  return y + "-" + MM + "-" + d + " "+h+ ":"+m+ ":" +s //多种时间格式的拼接
});
// import confirmMy from './components/tan.js'
// Vue.prototype.$confirmMy = confirmMy;
Vue.config.productionTip = false
// // 注册vant组件库
// let interfacess= require('os').networkInterfaces();
// console.log("inst"+ JSON.stringify(interfacess));
Vue.use(VueRouter)
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
